html {
	overflow-x: hidden !important;
	width: 100%;
	height: 100%;
	position: relative;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
}

body {
	border: 0;
	margin: 0;
	padding: 0;
	font-size: 15px;
	color: #6A6A6A;
	background: #fff;
}

h1,
h2,
h3 {
	color: #323232;
}

.body-inner {
	position: relative;
	overflow: hidden;
}

a:link,
a:visited {
	text-decoration: none;
}


a:hover {
	text-decoration: none;
	color: inherit;
}

a.read-more {
	color: #323232;
}

section {
	padding: 80px 0;
	position: relative;
}

.ts-padding {
	padding: 50px;
}

.p-0 {
	padding: 0;
}

.media>.float-left {
	margin-right: 20px;
}

.heading {
	padding-bottom: 50px;
	margin-top: 10px;
	transition: all 0.3s ease 0s;
}

.gap-60 {
	clear: both;
	height: 60px;
}

.gap-40 {
	clear: both;
	height: 40px;
}

.gap-30 {
	clear: both;
	height: 30px;
}

.gap-20 {
	clear: both;
	height: 20px;
}

a:focus {
	outline: 0;
}

ul.circle,
ul.check-list {
	list-style: none;
	margin: 0;
	padding: 0;
}

ul.circle li {
	position: relative;
	margin-left: 30px;
	padding-bottom: 20px;

}

ul.circle li:before {
	content: "\f111";
	font-family: FontAwesome;
	font-size: 8px;
	line-height: 8px;
	border: 2px solid rgba(0, 0, 0, .1);
	border-radius: 100%;
	position: absolute;
	left: -33px;
	top: 5px;
}

ul.unstyled {
	list-style: none;
	margin: 0;
	padding: 0;
}

ul.check-list li {
	margin-bottom: 5px;
}

ul.check-list li i {
	margin-right: 10px;
	font-size: 14px !important;
}

ul.arrow li:before {
	content: "\f105";
	font-family: FontAwesome;
	margin-right: 10px;
}

blockquote p {
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
	font-style: italic;
	color: #959595;
}

blockquote {
	margin: 20px 0;
	padding: 10px 20px;
}

/* Common styles
================================================== */

/* Angle */

.angle:before {
	background: #fff;
	content: "";
	height: 75px;
	top: -37px;
	position: absolute;
	width: 100%;
	z-index: 2;
	transform: skew(0deg, 2deg);
}

.portfolio.angle:before {
	background: #f5f5f5;
}

.portfolio.angle {
	padding-bottom: 120px;
}

.clients.angle {
	padding: 120px 0;
}

/* Parallax */

.parallax {
	background-size: cover !important;
	background-repeat: no-repeat;
	background-position: 50% 0;
	background-attachment: fixed !important;
	padding: 110px 0;
	color: #fff;
	position: relative;
}

.parallax h2,
.parallax h3 {
	color: #fff;
	margin-top: 0;
}

.parallax .btn {
	color: #fff;
}

.parallax-overlay {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: url(../images/overaly.png);
}

.parallax h3 {
	margin-bottom: 30px;
}

.parallax1 {
	background: url(../images/parallax/parallax1.jpg) no-repeat;
}

.parallax2 {
	background: url(../images/parallax/parallax2.jpg) no-repeat;
}

.parallax3 {
	background: url(../images/parallax/parallax3.jpg) no-repeat;
}

.parallax4 {
	background: url(../images/parallax/parallax4.jpg) no-repeat;
}

.parallax .btn.solid {
	margin-left: 10px;
}


/* Deafult title */

.title-icon {
	font-size: 32px;
	margin-right: 15px;
	margin-top: 9px;
	color: #959595;
}

.title-icon.classic {
	margin-top: 0;
}

.title {
	position: relative;
	margin: 0;
	line-height: 32px;
	border-left: 2px solid #e7e7e7;
	display: inline-block;
	padding-left: 15px;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-ms-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

.title.classic {
	line-height: 40px;
	height: 34px;
}

.title-desc {
	font-family: 'Lato', serif;
	font-size: 16px;
	line-height: 18px;
	font-weight: 400;
	text-transform: capitalize;
	display: block;
	color: #6A6A6A
}

/* Title style 2 */

.title2 {
	position: relative;
	margin-top: 30px;
}

.title2:after {
	content: '';
	width: 150px;
	left: 50%;
	margin-left: -75px;
	top: 50%;
	height: 3px;
	background: #dedede;
	position: absolute;
}

.title2 .title-desc {
	padding-top: 30px;
}


/* Title border */

.title-border {
	line-height: normal;
	margin-top: 0;
}

.widget-title {
	margin-bottom: 25px;
	font-size: 20px;
}

.post-title {
	font-size: 24px;
	line-height: normal;
}

.post-title a {
	color: #323232;
}

.long-desc {
	font-size: 16px;
}


/* Icon pentagon */

.icon-pentagon {
	display: inline-block;
	height: 24px;
	width: 40px;
	position: relative;
	color: #fff;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-ms-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

.icon-pentagon:hover {
	color: rgba(0, 0, 0, .5);
}

.icon-pentagon:before {
	border-left: 20px solid rgba(0, 0, 0, 0);
	border-right: 20px solid rgba(0, 0, 0, 0);
	top: -10px;
	content: "";
	height: 0;
	left: 0;
	position: absolute;
	width: 0;
}

.icon-pentagon:after {
	border-left: 20px solid rgba(0, 0, 0, 0);
	border-right: 20px solid rgba(0, 0, 0, 0);
	bottom: -10px;
	content: "";
	height: 0;
	left: 0;
	position: absolute;
	width: 0;
}

.icon-pentagon i {
	font-size: 20px;
	line-height: 24px;
}

.service-icon {
	height: 46px;
	width: 90px;
	margin-bottom: 50px;
}

.service-icon:before {
	border-left: 45px solid rgba(0, 0, 0, 0);
	border-right: 45px solid rgba(0, 0, 0, 0);
	top: -30px;
}

.service-icon:after {
	border-left: 45px solid rgba(0, 0, 0, 0);
	border-right: 45px solid rgba(0, 0, 0, 0);
	bottom: -30px;
}

.service-icon i {
	font-size: 36px;
	line-height: 46px;
}

/* Carousel controller */

.carousel-control {
	width: auto;
}

.ts-carousel-controller {
	text-align: center;
	padding-top: 50px;
	z-index: 1;
	position: relative;
}

.ts-carousel-controller .left,
.ts-carousel-controller .right,
.owl-controls .owl-prev,
.owl-controls .owl-next {
	cursor: pointer;
	background: #f6f6f6;
	width: 38px;
	height: 38px;
	line-height: 38px;
	color: #989898;
	opacity: 1;
	top: auto;
	text-shadow: none;
	display: inline-block;
	text-align: center;
	border-radius: 100%;
}

.owl-controls .owl-next {
	margin-left: 6px;
}

.ts-carousel-controller .left:hover,
.ts-carousel-controller .right:hover,
.owl-controls .owl-prev:hover,
.owl-controls .owl-next:hover {
	color: #fff;
}

.owl-controls .owl-page,
.owl-controls .owl-buttons div {
	display: inline-block;
}

/* Input form */

.form-control {
	box-shadow: none;
	border: 1px solid #dedede;
	padding: 6px 20px;
	height: 50px;
	background: none;
	color: #959595;
	font-size: 14px;
	border-radius: 0;
}

.form-control:focus {
	box-shadow: none;
}

/*-- Flex slider --*/

.portfolio-slider .flexslider,
.ts-flex-slider .flexslider {
	border: 0;
	box-shadow: none;
}

.portfolio-slider .flex-direction-nav a,
.ts-flex-slider .flex-direction-nav a {
	opacity: 0;
	background: #000;
	color: #fff !important;
	text-align: center;
	text-shadow: none;
	line-height: 40px;
}

.portfolio-slider .flex-direction-nav a:before {
	font-size: 32px;
}

.portfolio-slider .flex-direction-nav a:hover,
.ts-flex-slider .flex-direction-nav a:hover {
	color: #fff;
	opacity: 1;
}

.flexportfolio.flexslider .slides img {
	height: auto;
}

/*-- Pagination --*/

.pagination li a {
	border-radius: 0 !important;
	margin-right: 8px;
}

.pagination>.active>a,
.pagination>.active>a:hover,
.pagination>li>a:hover {
	color: #fff;
	border: 1px solid transparent;
}